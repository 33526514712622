<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>安评报告管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="安评报告" icon="md-paper">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="reportManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="添加报告" icon="ios-add-circle-outline">
            <div class="form-wrapper">
              <Form
                ref="addReport"
                :model="newReport"
                :rules="ruleValidate"
                :label-width="250"
              >
                <FormItem label="请选择线路：" prop="lineCode">
                  <Select v-model="newReport.lineCode">
                    <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="委托方：" prop="customer">
                  <Input v-model="newReport.customer" placeholder="" />
                </FormItem>
                <FormItem label="受托方：" prop="worker">
                  <Input v-model="newReport.worker" placeholder="" />
                </FormItem>
                <FormItem label="报告类型：" prop="reportType">
                  <Select v-model="newReport.reportType" style="width: 200px">
                    <Option
                      v-for="item in option.reportTypeChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="评价时间：" prop="evaluateTime">
                  <input type="number" v-model="newReport.evaluateTime" style="width: 200px"/>
                </FormItem>
                <FormItem label="下一次评价时间：" prop="nextEvaluateTime">
                  <input type="number" v-model="newReport.nextEvaluateTime" style="width: 200px"/>
                </FormItem>
                <FormItem label="报告状态：" prop="reportStat">
                  <Select v-model="newReport.reportStat" style="width: 200px">
                    <Option
                      v-for="item in option.reportStatChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="项目状态：" prop="projectStat">
                  <Select v-model="newReport.projectStat" style="width: 200px">
                    <Option
                      v-for="item in option.projectStatChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="报告摘要：" prop="reportConclusion">
                  <Input
                    v-model="newReport.reportConclusion"
                    type="textarea"
                    :autosize="{ minRows: 10, maxRows: 50 }"
                    placeholder=""
                  />
                </FormItem>
                <FormItem label="备注：" prop="remark">
                  <Input
                    v-model="newReport.remark"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="报告文件上传：" prop="pdfFile">
                  <Upload
                    v-if="file === null"
                    ref="upload"
                    :before-upload="handleUpload"
                    :on-success="handleSuccess"
                    action=""
                  >
                    <Button
                      class="button-wrapper"
                      icon="ios-cloud-upload-outline"
                      >请选择上传文件</Button
                    >
                  </Upload>
                  <div v-if="file !== null" class="file-space-wrapper">
                    <div class="file-space">
                      <div class="file-icon-wrapper">
                        <Icon type="ios-document-outline" :size="50" />
                      </div>
                      <div class="fp-inner">
                        <p>{{ file.name }}</p>
                        <p>{{ file.size / 1024 + "K" }}</p>
                      </div>
                      <div class="del-icon-wrapper">
                        <Button
                          class="del-button"
                          @click="handleRemove"
                          type="text"
                          icon="ios-trash"
                          size="large"
                        ></Button>
                      </div>
                    </div>
                  </div>
                </FormItem>
                <FormItem>
                  <Button
                    @click="handleSubmit('addReport')"
                    :loading="loadingStatus"
                    size="large"
                    >{{ loadingStatus ? "Uploading" : "确认提交" }}</Button
                  >
                  <Button
                    @click="handleReset('addReport')"
                    size="large"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>报告信息修改</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
          <Form
            ref="alterReport"
            :model="currentReport"
            :rules="ruleValidateAlter"
            :label-width="250"
          >
            <FormItem label="请选择线路：" prop="lineCode">
              <Select v-model="currentReport.lineCode">
                <Option
                  v-for="item in activeOption.lineChoices"
                  :value="item.line_code"
                  :key="item.line_code"
                  >{{ item.line_name }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="委托方：" prop="customer">
              <Input v-model="currentReport.customer" placeholder="" />
            </FormItem>
            <FormItem label="受托方：" prop="worker">
              <Input v-model="currentReport.worker" placeholder="" />
            </FormItem>
            <FormItem label="报告类型：" prop="reportType">
              <Select v-model="currentReport.reportType" style="width: 200px">
                <Option
                  v-for="item in option.reportTypeChoices"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="评价时间：" prop="evaluateTime">
              <input type="number" v-model="currentReport.evaluateTime" style="width: 200px"/>
            </FormItem>
            <FormItem label="下一次评价时间：" prop="nextEvaluateTime">
              <input type="number" v-model="currentReport.nextEvaluateTime" style="width: 200px"/>
            </FormItem>
            <FormItem label="报告状态：" prop="reportStat">
              <Select v-model="currentReport.reportStat" style="width: 200px">
                <Option
                  v-for="item in option.reportStatChoices"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="项目状态：" prop="projectStat">
              <Select v-model="currentReport.projectStat" style="width: 200px">
                <Option
                  v-for="item in option.projectStatChoices"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="报告摘要：" prop="reportConclusion">
              <Input
                v-model="currentReport.reportConclusion"
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 50 }"
                placeholder=""
              />
            </FormItem>
            <FormItem label="备注：" prop="remark">
              <Input
                v-model="currentReport.remark"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 5 }"
                placeholder="Enter something..."
              />
            </FormItem>
            <FormItem label="报告文件上传：" prop="pdfFile">
              <Upload
                v-if="file === null"
                ref="upload"
                :before-upload="handleUploadAlter"
                :on-success="handleSuccess"
                action=""
              >
                <Button class="button-wrapper" icon="ios-cloud-upload-outline"
                  >请选择上传文件</Button
                >
              </Upload>
              <div v-if="fileAlter !== null" class="file-space-wrapper">
                <div class="file-space">
                  <div class="file-icon-wrapper">
                    <Icon type="ios-document-outline" :size="50" />
                  </div>
                  <div class="fp-inner">
                    <p>{{ fileAlter.name }}</p>
                    <p>{{ fileAlter.size / 1024 + "K" }}</p>
                  </div>
                  <div class="del-icon-wrapper">
                    <Button
                      class="del-button"
                      @click="handleRemove"
                      type="text"
                      icon="ios-trash"
                      size="large"
                    ></Button>
                  </div>
                </div>
              </div>
            </FormItem>
          </Form>
          <Spin fix v-show="loadingStatus"></Spin>
        </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterReport')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function reportFac() {
  return {
    id: 0,
    lineCode: "",
    evaluateTime: 2021,
    worker: "上海轨道交通检测认证（集团）有限公司",
    reportType: '',
    nextEvaluateTime: 2021,
    customer: "成都市交通运输局",
    reportStat: "",
    projectStat: "",
    remark: "",
    reportConclusion: "",
    pdfFile: "",
  };
}

export default {
  name: "reportManage",
  components: {
    queryTable,
  },
  props: {},
  data() {
    return {
      askData: "reportManage",
      update_count: 0,
      columnsFormatData: [
        {
          title: "线路名称",
          key: "line_name",
          align: "center",
          width: 90,
          render: (h, p) => {
            let url = "/baseInfo/line_c/" + p.row.line_id;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank",
                },
              },
              p.row.line_name
            );
          },
        },
        {
          title: "安评时间",
          render: (h, p) => {
            return h(
              "span",
              {
                attrs: {},
              },
              p.row.evaluate_time.split("-")[0]
            );
          },
          sortable: true,
          width: 120,
          align: "center",
          filters: [
            {
              label: ">2017",
              value: 1,
            },
            {
              label: "≤2017",
              value: 2,
            },
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return row.score > 2017;
            } else if (value === 2) {
              return row.score < 2017;
            }
          },
        },
        {
          title: "受托方",
          key: "worker",
          align: "center",
        },
        {
          title: "报告类型",
          key: "report_type",
          align: "center",
          width: 95,
        },
        {
          title: "报告状态",
          key: "report_stat",
          width: 95,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 140,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      atimated: false,
      newReport: reportFac(),
      currentReport: reportFac(),
      ruleValidate: {
        lineCode: [
          {
            required: true,
            type: "string",
            message: "请选择线路！",
            trigger: "blur",
          },
        ],
        // evaluateTime: [
        //   {
        //     required: true,
        //     type: "number",
        //     message: "请选择评价时间！",
        //     trigger: "blur",
        //   },
        // ],
        // nextEvaluateTime: [
        //   {
        //     required: false,
        //     message: "请输入下次评价时间！",
        //     trigger: "blur",
        //   },
        // ],
        customer: [
          { required: true, message: "请输入委托方名称！", trigger: "blur" },
          {
            type: "string",
            message: "请填写委托方全称！",
            trigger: "blur",
          },
        ],
        worker: [
          { required: true, message: "请输入受托方名称！", trigger: "blur" },
          {
            type: "string",
            message: "请填写受托方全称！",
            trigger: "blur",
          },
        ],
        reportConclusion: [
          { required: true, message: "请输入报告摘要！", trigger: "blur" },
        ],
        reportType: [
          { required: true, message: "请输入报告类型！", trigger: "blur" },
        ],
        reportStat: [
          { required: true, message: "请选择报告状态！", trigger: "blur" },
        ],
        projectStat: [
          { required: true, message: "请选择项目状态！", trigger: "blur" },
        ],
        pdfFile: [
          {
            type: "string",
            required: true,
          },
        ],
      },
      ruleValidateAlter: {
        // lineCode: [
        //   {
        //     required: true,
        //     type: "string",
        //     message: "请选择线路！",
        //     trigger: "blur",
        //   },
        // ],
        // evaluateTime: [
        //   {
        //     required: true,
        //     type: "date",
        //     message: "请选择评价时间！",
        //     trigger: "blur",
        //   },
        // ],
        // nextEvaluateTime: [
        //   {
        //     required: true,
        //     type: "date",
        //     message: "请输入下次评价时间！",
        //     trigger: "blur",
        //   },
        // ],
        // customer: [
        //   { required: true, message: "请输入委托方名称！", trigger: "blur" },
        //   {
        //     type: "string",
        //     message: "请填写委托方全称！",
        //     trigger: "blur",
        //   },
        // ],
        // worker: [
        //   { required: true, message: "请输入受托方名称！", trigger: "blur" },
        //   {
        //     type: "string",
        //     message: "请填写受托方全称！",
        //     trigger: "blur",
        //   },
        // ],
        // reportConclusion: [
        //   { required: true, message: "请输入报告摘要！", trigger: "blur" },
        // ],
        // reportType: [
        //   { required: true, message: "请输入报告类型！", trigger: "blur" },
        // ],
        // reportStat: [
        //   { required: true, message: "请选择报告状态！", trigger: "blur" },
        // ],
        // projectStat: [
        //   { required: true, message: "请选择项目状态！", trigger: "blur" },
        // ],
      },
      file: null,
      fileAlter: null,
      option: {
        reportStatChoices: [
          {
            value: "进行中",
            label: "进行中",
          },
          {
            value: "完成",
            label: "完成",
          },
        ],
        projectStatChoices: [
          {
            value: "进行中",
            label: "进行中",
          },
          {
            value: "结题",
            label: "结题",
          },
        ],
        reportTypeChoices: [],
      },
      activeOption:{

      },
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getStaticOption();
    this.getActiveOption();
  },
  methods: {
    getStaticOption() {
      this.$axios
        .post(this.$url + "/static_option", {
          userToken: userToken,
          askData: "reportManage",
        })
        .then((response) => {
            this.option = response.data.res_record;
        });
    },
     getActiveOption() {
    this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData: "allLineList",
        })
        .then((response) => {
          this.activeOption = {'lineChoices':response.data.res_record};
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.file = null;
      this.fileAlter = null;
    },
    handleRemove() {
      this.file = null;
      this.fileAlter = null;
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("file", this.file);
          form.append("userToken", userToken);
          form.append("askData", "addReport");
          form.append("newReport", JSON.stringify(this.newReport));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/report_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("规范添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    handleSuccess() {},
    handleUpload(file) {
      if (
        file.name.split(".").pop() !== "pdf" &&
        file.name.split(".").pop() !== "doc" &&
        file.name.split(".").pop() !== "docx"
      ) {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " +
            file.name +
            " 的格式不正确，请选择后缀为“.pdf或.doc或.docx”的文件!",
        });
      } else if (file.size > 104800000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于100MB!",
        });
      } else {
        this.file = file;
        this.newReport.pdfFile = file.name;
      }
      return false;
    },
    handleUploadAlter(file) {
      if (
        file.name.split(".").pop() !== "pdf" &&
        file.name.split(".").pop() !== "doc" &&
        file.name.split(".").pop() !== "docx"
      ) {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " +
            file.name +
            " 的格式不正确，请选择后缀为“.pdf或.doc或.docx”的文件!",
        });
      } else if (file.size > 102400000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于10MB!",
        });
      } else {
        this.fileAlter = file;
        this.currentReport.pdfFile = file.name;
      }
      return false;
    },
    modal_save(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("file", this.fileAlter);
          form.append("userToken", userToken);
          form.append("askData", "alterReport");
          form.append("currentReport", JSON.stringify(this.currentReport));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/report_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("报告信息修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该报告：",
        content:
          "<p>" +
          "线路名称：" +
          value.line_name +
          "</p>" +
          "<p>" +
          "报告年度：" +
          value.year +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/report_alter", {
          userToken: userToken,
          askData: "delReport",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value) {
      this.currentReport = reportFac();
      this.currentReport.id = value.id;
      this.currentReport.lineCode = value.line_code;
      this.currentReport.evaluateTime = parseInt(value.evaluate_time.substr(0,4));
      this.currentReport.worker = value.worker;
      this.currentReport.reportType = value.report_type;
      this.currentReport.nextEvaluateTime = parseInt(value.next_evaluate_time.substr(0,4));
      this.currentReport.customer = value.customer;
      this.currentReport.reportStat = value.report_stat;
      this.currentReport.projectStat = value.project_stat;
      this.currentReport.remark = value.remark;
      this.currentReport.reportConclusion = value.report_conclusion;
      this.currentReport.pdfFile = '';
      this.fileAlter = null;
      console.log(this.currentReport);
      this.scModal = true;
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}


input{
  display: inline-block;
    width: 100%;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 14px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border .2s ease-in-out,background .2s ease-in-out,box-shadow .2s ease-in-out;
}

input:focus {
    border-color: #57a3f3;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(45,140,240,.2);
}

input:hover {
    border-color: #57a3f3;
}
</style>